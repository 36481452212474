import { createRouter, createWebHistory } from 'vue-router';
import ServerSelection from './components/ServerSelection.vue';
import ServerDetails from './components/ServerDetails.vue';
import DbSelection from './components/DbSelection.vue';
import SchemaSelection from './components/SchemaSelection.vue';
import TableSelection from './components/TableSelection.vue';

const routes = [
    {
        path: '/',
        name: 'ServerSelection',
        component: ServerSelection
    },
    {
        path: '/server-details',
        name: 'ServerDetails',
        component: ServerDetails,
        props: true
    },
    {
        path: '/db-selection',
        name: 'DbSelection',
        component: DbSelection,
        props: true
    },
    {
        path: '/schema-selection',
        name: 'SchemaSelection',
        component: SchemaSelection,
        props: true
    },
    {
        path: '/table-selection',
        name: 'TableSelection',
        component: TableSelection,
        props: true
    },
    // This will redirect any unrecognized path to the server selection page
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
];


const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;

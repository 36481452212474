
    import { ref } from 'vue';
    import axios from 'axios';
    import { useRouter } from 'vue-router';

    export default {
        name: 'SchemaSelection',

        data() {
            return {
            };
        },

        setup() {
            const historyState = window.history.state;
            const schemaNames = ref(historyState.schemaNames as string[]);
            const dbConfigId = ref(historyState.dbConfigId as number);
            const selectedSchemaName = ref('');
            const router = useRouter();
            async function fetchTableNames(): Promise<[string[], number]> {
                try {
                    console.log("fetchTableNames");
                    const selectedSchemaDetails = {
                        SchemaName: selectedSchemaName.value,
                        DbConfigId: dbConfigId.value,
                        Description: "sadasdsad"
                    };
                    console.log(selectedSchemaDetails)
                    const response = await axios.post('/api/setup/schema', selectedSchemaDetails);
                    console.log(response)

                    const tableNames = response.data.tableNames;
                    const configId = response.data.configId;
                    return [tableNames, configId];
                } catch (error) {
                    console.error('Error fetching schemaNames:', error);
                    return [[], -1]; // Clear the list on error
                }
            }
            function goNext() {
                console.log("goNext");
                fetchTableNames().then(([tableNames, configId]) => {
                    console.log("try route");
                    router.push({
                        name: 'TableSelection',
                        state: {
                            tableNames,
                            schemaConfigId: configId
                        }
                    });
                });
            }
            function goBack() {
                router.go(-1);
            }
            return {
                schemaNames,
                dbConfigId,
                selectedSchemaName,
                goNext,
                goBack
            };
        },
        methods: {

        },
    };


    import { ref, watch } from 'vue';
    import axios from 'axios';
    import { useRouter } from 'vue-router';
    export default {
        name: 'TableSelection',

        data() {
            return {
                pullMethods: ['Once', 'Listener', 'Interval']
            };
        },
        setup() {
            const historyState = window.history.state;
            const tableNames = ref<string[]>(historyState.tableNames);
            const schemaConfigId = ref<number>(historyState.schemaConfigId);
            const selectedPullMethod = ref<string>('');
            const selectedTableNames = ref<string[]>([]);
            const allTablesCheckbox = ref<boolean>(false);
            const router = useRouter();
            watch(allTablesCheckbox, (newValue) => {
                if (newValue) {
                    selectedTableNames.value = tableNames.value.slice();
                } else {
                    selectedTableNames.value = [];
                }
            });

            async function downloadTable(tableName: string): Promise<number> {
                try {
                    console.log("download");
                    const selectedTableDetails = {
                        TableName: tableName,
                        SchemaConfigId: schemaConfigId.value,
                        Description: "sadasdsad",
                        PullMethod: selectedPullMethod.value
                    };
                    console.log(selectedTableDetails);
                    const response = await axios.post('/api/setup/table', selectedTableDetails);
                    console.log(response);
                    const configId = response.data.configId;
                    return configId;
                } catch (error) {
                    console.error('Error download table:', error);
                    return -1; 
                }
            }

            async function downloadAllTables() {
                for (const tableName of selectedTableNames.value) {
                    console.log("strarting download - " + tableName);
                    await downloadTable(tableName);
                }
            }

            function finish() {
                downloadAllTables().then(() => {
                    console.log("Downloaded all tables successfully");
                });
            }

            function goBack() {
                router.go(-1);
            }

            return {
                tableNames,
                schemaConfigId,
                selectedPullMethod,
                finish,
                selectedTableNames,
                allTablesCheckbox,
                goBack
            };
        }
    };


    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import axios from 'axios';

    export default {
        name: 'ServerDetails',

        data() {
            return {
            };
        },
        setup() {
            const historyState = window.history.state;
            const serverType = ref(historyState.serverType || null);
            const serverName = ref(historyState.serverName as string || '');
            const host = ref(historyState.host as string || '');
            const userId = ref(historyState.userId as string || '');
            const password = ref(historyState.password as string || '');
            const router = useRouter();
            async function fetchDatabaseNames(): Promise<[string[], number]> {
                try {
                    const selectedServerDetails = {
                        ServerName: serverName.value,
                        ConnectionString: "Data Source=" + host.value + ";User ID=" + userId.value + ";Password=" + password.value + ";TrustServerCertificate=true;",
                        ServerType: serverType.value,
                        Description: "sadasdsad"
                    };
                    const response = await axios.post('/api/setup/server', selectedServerDetails);
                    console.log(response);
                    const dbNames = response.data.databaseNames;
                    const configId = response.data.configId;
                    return [dbNames, configId];
                } catch (error) {
                    console.error('Error fetching databases:', error);
                    return [[], -1]; // Clear the list on error
                }
            }

            function goNext() {
                fetchDatabaseNames().then(([dbNames, configId]) => {
                    console.log("try route")
                    router.push({
                        name: 'DbSelection',
                        state: {
                            dbNames,
                            serverConfigId: configId
                        }
                    });
                });
            }
            function goBack() {
                router.go(-1);
            }
            console.log(serverType.value);

            return {
                serverType,
                serverName,
                host,
                userId,
                password,
                goNext,
                goBack
            };
        },
        methods: {

            testConnection() {
                // Logic to test the server connection
            },
            saveDetails() {
                // Logic to save the server details
            },
        }
    };

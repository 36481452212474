<template>
    <div id="app">
        <div class="content">
            <img alt="SEAL logo" src="@/assets/seal.svg" class="logo">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'App',
    };
</script>

<style>
    /* Base typography settings */
    body {
        font-family: 'Roboto', sans-serif;
        color: #333;
        background-color: #f5f5f5;
        margin: 0;
        padding: 0;
    }

    /* Content container */
    .content {
        padding: 20px;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    /* Logo */
    .logo {
        display: block;
        margin: 0 auto;
        max-width: 200px;
        padding: 20px 0;
    }

    /* Buttons */
    button {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        letter-spacing: 0.1em;
        padding: 12px 24px;
        margin: 8px;
        border: none;
        border-radius: 4px;
        background-color: #6200ea;
        color: #ffffff;
        cursor: pointer;
        transition: background-color 0.3s, box-shadow 0.3s;
        outline: none;
        position: relative;
        overflow: hidden;
    }

        button:hover {
            background-color: #3700b3;
        }

        button:active {
            background-color: #27006f;
        }

        button:focus {
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        }

    /* Text fields */
    input[type="text"], input[type="password"], select {
        font-family: 'Roboto', sans-serif;
        padding: 12px;
        margin: 8px 0;
        width: 100%;
        border: none;
        border-radius: 4px;
        border-bottom: 2px solid #6200ea;
        background-color: #fff;
        color: #333;
        transition: border-color 0.3s;
        outline: none;
    }

        input[type="text"]:focus, input[type="password"]:focus, select:focus {
            border-color: #3700b3;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

    /* Card-like containers */
    .card {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin: 16px;
        padding: 16px;
        position: relative;
    }

    /* Ripple effect on button click */
    button:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        background: rgba(255, 255, 255, 0.8); /* ripple color */
        border-radius: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        pointer-events: none;
        transition: width 0.3s, height 0.3s, opacity 0.3s;
    }

    button:active:after {
        width: 150%; /* Adjust this if you want a smaller or larger ripple relative to button size */
        height: 150%; /* Same as above */
        opacity: 0.2;
        transition: width 0.3s, height 0.3s, opacity 0.3s;
    }
</style>


    import { ref } from 'vue';
    import axios from 'axios';
    import { useRouter } from 'vue-router';

    export default {
        name: 'DbSelection',

        data() {
            return {
            };
        },

        setup() {
            const historyState = window.history.state;
            const dbNames = ref(historyState.dbNames as string[]);
            const serverConfigId = ref(historyState.serverConfigId as number);
            const selectedDbName = ref('');
            const allDb = ref(false); // Initialize allDb as false
            const router = useRouter();
            console.log(dbNames.value)
            async function fetchSchemaNames(): Promise<[string[], number]> {
                try {
                    const selectedDbDetails = {
                        DbName: selectedDbName.value,
                        ServerConfigId: serverConfigId.value,
                        Description: "sadasdsad"
                    };
                    const response = await axios.post('/api/setup/db', selectedDbDetails);
                    console.log(response);

                    const schemaNames = response.data.schemaNames;
                    const configId = response.data.configId;
                    return [schemaNames, configId];
                } catch (error) {
                    console.error('Error fetching schemaNames:', error);
                    return [[], -1];
                }
            }

            async function goNext() {
                console.log(allDb.value);
                if (allDb.value) {
                    const selectedDbDetails = {
                        DbName: selectedDbName.value,
                        ServerConfigId: serverConfigId.value,
                        Description: "sadasdsad"
                    };
                    const response = await axios.post('/api/setup/db', selectedDbDetails, {
                        headers: {
                            alldb: true
                        }
                    });
                    console.log(response);
                } else {
                    fetchSchemaNames().then(([schemaNames, configId]) => {
                        router.push({
                            name: 'SchemaSelection',
                            state: {
                                schemaNames,
                                dbConfigId: configId
                            }
                        });
                    });
                }
            }
            async function goBack() {
                router.go(-1);
            }
            return {
                dbNames,
                serverConfigId,
                selectedDbName,
                allDb,
                goNext,
                goBack
            };
        },
        methods: {

        }
    };
